import { DocumentData } from "firebase/firestore";
import { WordType } from "../types";

export const mapFromDocumentData = (documentData: DocumentData): WordType => {
  return {
    frequency: documentData.frequency,
    word: documentData.word,
    genre: documentData.genre,
    article: documentData.article,
    nature:
      typeof documentData.nature === "string"
        ? documentData.nature.split(",")
        : documentData.nature,
    nombre: documentData.nombre,
    lemme: documentData.lemme,
    phonetic: documentData.phonetic,
    simple: documentData.simple,
    definitions: documentData.definitions,
    synonyms: documentData.synonyms,
    images: documentData.images,
  };
};
