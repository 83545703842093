import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { ButtonBase, Stack, Typography } from "@mui/material";

type BackButtonProps = { noText?: boolean };

const BackButton = ({ noText }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <ButtonBase
      onClick={() => {
        navigate(-1);
      }}
    >
      <Stack direction="row" alignItems="center" p={1} spacing={1}>
        <ArrowBack color="primary" />
        {!noText && <Typography color="primary">Retour</Typography>}
      </Stack>
    </ButtonBase>
  );
};

export default BackButton;
