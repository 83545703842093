import { Card, CardActionArea, CardContent, Typography } from "@mui/material";

export type CardMenuProps = { title: string; link: string; disabled: boolean };
const CardMenu = ({ title, link, disabled }: CardMenuProps) => {
  return (
    <Card>
      <CardActionArea disabled={disabled} href={link}>
        <CardContent>
          <Typography variant="h5" color={disabled ? "gray" : "primary"}>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardMenu;
