import { doc, getDoc } from "firebase/firestore";
import { WordType } from "../types";
import { wordsCollection } from "../../../lib/firebase/firestore";
import { mapFromDocumentData } from "../mapper";

export const getWord = async (word: string): Promise<WordType | null> => {
  return getDoc(doc(wordsCollection, word)).then((doc) => {
    if (doc.exists()) {
      return mapFromDocumentData(doc.data());
    }
    return null;
  });
};
