import { deleteDoc, doc } from "firebase/firestore";
import { addChange } from "../../changes/api/add";
import { wordsCollection } from "../../../lib/firebase/firestore";

export const deleteWord = async (word: string) => {
  return deleteDoc(doc(wordsCollection, word))
    .then(() => {
      addChange(
        {
          word: word,
        },
        "delete"
      );
      return true;
    })
    .catch((e) => {
      console.error("Erreur en supprimant " + word);
      console.error(e);
      return false;
    });
};
