import { limit, getDocs, query, where } from "firebase/firestore";
import { getNextString } from "../../words/utils";
import { imagesIndexCollection } from "../../../lib/firebase/firestore";
import { ImageLinkType } from "../types";

export const getImages = async (searchWord: string) => {
  const startsWith = searchWord;
  const endSearch = getNextString(startsWith);

  const q = query(
    imagesIndexCollection,
    where("name", ">=", startsWith),
    where("name", "<", endSearch),
    limit(10)
  );

  const querySnapshot = await getDocs(q);
  let imagesLink: ImageLinkType[] = [];
  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    docData.urls.forEach((url: string) =>
      imagesLink.push({ name: docData.name, url: url })
    );
  });
  return imagesLink;
};
