import { ComponentType, useEffect, useState } from "react";
import { LoginForm } from "./LoginForm";
import { auth } from "../../../lib/firebase";

export function withAuth(Component: ComponentType<any>) {
  return function AuthWrapper(props: any) {
    const [user, setUser] = useState(auth.currentUser);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setUser(user);
      });
      return unsubscribe;
    }, []);

    if (!user) {
      return <LoginForm />;
    }
    return <Component {...props} />;
  };
}
