import { UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../../lib/firebase";

export type LoginCredentials = {
  email: string;
  password: string;
};

export const login = (data: LoginCredentials): Promise<UserCredential> => {
  return signInWithEmailAndPassword(auth, data.email, data.password);
};
