export const getNextString = (string: string) => {
  if (string.charAt(string.length - 1).toUpperCase() === "Z") {
    if (string.length > 1) {
      return (
        string.substring(0, string.length - 2) +
        String.fromCharCode(string.charCodeAt(string.length - 2) + 1)
      );
    }
    return string.concat("z");
  }
  return (
    string.substring(0, string.length - 1) +
    String.fromCharCode(string.charCodeAt(string.length - 1) + 1)
  );
};
