import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Slider,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import PhonWord from "../ColorizationPart/Core/PhonWord";
import WordsList from "../features/words/components/WordsList";
import { WordType } from "../features/words/types";
import {
  AddCircleOutline,
  ArrowBack,
  Clear,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  SettingsState,
  setHelpLevel,
} from "../features/settings/settingsSlice";
import TextToSpeech from "./TextToSpeech";
import { searchWords } from "../features/words/api";

const HomeContent = () => {
  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const word = searchParams.get("word");
  const backTo = searchParams.get("back");
  const settings: SettingsState = useAppSelector((state) => state.settings);

  // the words to display
  const [words, setWords] = React.useState<WordType[]>([]);
  // the word to search
  const [searchWord, setSearchWord] = React.useState<string>("");

  const goBack = () => {
    if (backTo === "admin") {
      navigate("/admin");
    } else {
      navigate("/?word=" + backTo);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value.toLowerCase());
  };

  const handleHelpLevelChange = (value: number) => {
    const helpLevel: 0 | 1 | 2 = value >= 2 ? 2 : value <= 0 ? 0 : 1;
    dispatch(setHelpLevel(helpLevel));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    // navigate on submit for analytics
    let to = "/?word=" + searchWord;
    if (backTo) {
      to += "&back=" + backTo;
    }
    navigate(to);
  };

  const forceRefreshWords = (word: PhonWord) => {
    if (word) {
      searchWords(word, settings).then((res) => setWords(res));
    }
  };

  const removeArticle = (word: string) => {
    if (
      word.startsWith("le ") ||
      word.startsWith("la ") ||
      word.startsWith("l'") ||
      word.startsWith("les ") ||
      word.startsWith("un ") ||
      word.startsWith("une ") ||
      word.startsWith("des ")
    ) {
      return word
        .replace("le ", "")
        .replace("la ", "")
        .replace("l'", "")
        .replace("les ", "")
        .replace("un ", "")
        .replace("une ", "")
        .replace("des ", "")
        .trim();
    }
    return word;
  };

  React.useEffect(() => {
    if (searchWord && searchWord.length > 3) {
      const pw = new PhonWord(removeArticle(searchWord));
      forceRefreshWords(pw);
    }
    // eslint-disable-next-line
  }, [searchWord]);

  React.useEffect(() => {
    if (word) {
      console.log("Word is defined:");
      console.log(word);
      setSearchWord(word);
      if (word.length <= 3) {
        const pw = new PhonWord(removeArticle(word));
        forceRefreshWords(pw);
      }
    }
    // eslint-disable-next-line
  }, [word]);

  React.useEffect(() => {
    if (searchWord && searchWord.length > 3) {
      const pw = new PhonWord(removeArticle(searchWord));
      forceRefreshWords(pw);
    }
    // eslint-disable-next-line
  }, [settings]);

  const MySlider = styled(Slider)(() => ({
    "& .MuiSlider-mark": {
      backgroundColor: theme.palette.secondary.main,
      height: 9,
      width: 3,
    },
  }));

  return (
    <Box>
      <Grid
        container
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.primary.light,
          padding: 2,
          paddingBottom: 3,
        }}
      >
        <Grid item xs={10} sm={5} md={4} sx={{ mt: 1 }}>
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography color="secondary" fontWeight="bold" variant="body1">
              Aide
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <IconButton
                onClick={() => handleHelpLevelChange(settings.helpLevel - 1)}
              >
                <RemoveCircleOutline color="secondary" />
              </IconButton>
              <MySlider
                color="secondary"
                onChange={(e, val) => handleHelpLevelChange(val as number)}
                sx={{
                  maxWidth: 140,
                  mx: 2,
                }}
                step={1}
                marks
                min={0}
                max={2}
                value={settings.helpLevel}
              />
              <IconButton
                onClick={() => handleHelpLevelChange(settings.helpLevel + 1)}
              >
                <AddCircleOutline color="secondary" />
              </IconButton>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={10} sm={6} md={4} sx={{ mt: 1 }}>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              mx: "auto",
              mt: 1,
              alignItems: "center",
              maxWidth: 320,
              height: 50,
            }}
          >
            {backTo && (
              <>
                <IconButton onClick={goBack}>
                  <ArrowBack color="primary" />
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              </>
            )}
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                color: theme.palette.primary.main,
                fontSize: "1.3rem",
              }}
              color="primary"
              autoFocus
              type="text"
              placeholder="Ecrire un mot"
              value={searchWord}
              onChange={handleChange}
            />
            {searchWord.trim() !== "" && (
              <>
                <IconButton
                  onClick={() => setSearchWord("")}
                  sx={{ p: 1 }}
                  aria-label="clear"
                >
                  <Clear color="primary" />
                </IconButton>
                <TextToSpeech word={searchWord} inSearchBar={true} />
              </>
            )}
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="submit"
              onClick={handleSubmit}
              sx={{ p: 1 }}
              aria-label="search"
            >
              <SearchIcon color="primary" />
            </IconButton>
          </Paper>
        </Grid>
        <Grid item md={4} sx={{ xs: "none", sm: "none", mt: 1 }}></Grid>
      </Grid>
      <WordsList words={words} />
    </Box>
  );
};

export default HomeContent;
