import { WordType } from "../types";
import { addWord } from "./add";
import { deleteWord } from "./delete";

export const renameWord = async (oldWord: string, newWord: WordType) => {
  const isDeleted = deleteWord(oldWord);
  if (!isDeleted) {
    return {
      error: "Erreur en supprimant " + oldWord,
    };
  }
  return addWord(newWord);
};
