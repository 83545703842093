import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import BackButton from "../../components/BackButton";
import { WordType } from "../../features/words/types";
import { useState } from "react";
import WordForm from "../../features/words/components/WordForm";
import { Add, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import PhonWord from "../../ColorizationPart/Core/PhonWord";
import { addWord } from "../../features/words/api";
import { withAuth } from "../../features/auth/components/AuthAccess";

const AddWordPage = () => {
  const navigate = useNavigate();
  const [word, setWord] = useState<WordType>({
    frequency: 0,
    word: "",
    lemme: "",
    genre: "",
    article: "",
    nature: [],
    nombre: "",
    phonetic: "",
    simple: "",
    definitions: [],
    synonyms: [],
    images: [],
  });

  const cancel = () => {
    navigate(-1);
  };

  const addWordToDatabase = () => {
    const newWord: WordType = {
      ...word,
      word: word.word.trim().toLowerCase(),
      lemme: word.word,
      definitions: word.definitions.filter((d) => d.trim().length > 0),
      synonyms: word.synonyms.filter((s) => s.trim().length > 0),
    };
    const phonWord = new PhonWord(newWord.word);
    newWord.simple = phonWord.ToPhonString("simple");
    newWord.phonetic = phonWord.ToPhonString("phonetic");
    addWord(newWord).then((added) => {
      if ("error" in added) {
        console.error(added.error);
      } else {
        console.log("Word created");
        cancel();
      }
    });
  };

  return (
    <Box pb={2}>
      <BackButton />
      <Card sx={{ m: 2 }}>
        <CardContent>
          <WordForm word={word} setWord={setWord} />
        </CardContent>
        <CardActions>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={cancel}>
              <Close fontSize="large" />
            </IconButton>
            <IconButton onClick={addWordToDatabase}>
              <Add color="success" fontSize="large" />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </Box>
  );
};

export default withAuth(AddWordPage);
