import { MouseEvent, useEffect, useState } from "react";
import { ContactSupport, Edit } from "@mui/icons-material";
import {
  Card,
  CardContent,
  Typography,
  Box,
  CardActions,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import ContactDialog from "../../../components/ContactDialog";
import { EstVoyelle } from "../../../ColorizationPart/Utils";
import TextToSpeech from "../../../components/TextToSpeech";
import axios from "axios";
import { WordType } from "../types";
import { auth } from "../../../lib/firebase";

interface WordProps {
  word: WordType;
}

const mapReadable = (nature: string, genre?: string) => {
  if (nature === "ADJ") {
    return "adj.";
  } else if (nature === "ADV") {
    return "adv.";
  } else if (nature === "VER") {
    return "v.";
  } else if (nature === "NOM") {
    if (genre === "MASC") {
      return "n.m.";
    } else if (genre === "FEMI") {
      return "n.f.";
    } else {
      return "nom";
    }
  } else if (nature === "PRE") {
    return "prép.";
  } else {
    return "";
  }
};

const addParenthesisWhenNeeded = (article: string, add: boolean) => {
  if (add) {
    return "(" + article + ")";
  } else {
    return article;
  }
};

const ARASAAC_SEARCH_API = "https://api.arasaac.org/api/pictograms";

const Word = ({ word }: WordProps) => {
  const isAuthenticated = Boolean(auth.currentUser);

  const [extraImage, setExtraImage] = useState<string | null>(null);
  const [article, setArticle] = useState<string>();
  const [articleReadable, setArticleReadable] = useState<string>();
  const [nature, setNature] = useState<string>();

  const [isContactDialogOpen, setContactDialogOpen] = useState<boolean>(false);

  const openContactDialog = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContactDialogOpen(true);
  };
  const closeContactDialog = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setContactDialogOpen(false);
  };

  useEffect(() => {
    // wait for word to be defined
    if (word) {
      // Define the article before the word
      let addParenthesis = false;
      if (word.nature) {
        // define the nature of the word in a more readable way
        const keep = ["NOM", "ADJ", "VER", "ADV", "PRE"];
        const naturesKept = word.nature.filter((w) => keep.includes(w));
        naturesKept.sort((a, b) => keep.indexOf(a) - keep.indexOf(b));
        const naturesClean = naturesKept
          .map((nature) => mapReadable(nature, word.genre))
          .filter((n) => n !== "");
        setNature(naturesClean.join(", "));
        if (word.nature.includes("NOM") && word.genre) {
          // Add parenthesis if  word is more than a name
          if (naturesClean.length > 1) {
            addParenthesis = true;
          }
          if (word.article) {
            setArticle(word.article);
            setArticleReadable(word.article + " ");
          } else {
            if (
              word.nombre === "PLUR" ||
              (!word.nombre && ["x", "s"].includes(word.word.at(-1)!))
            ) {
              setArticle(addParenthesisWhenNeeded("les", addParenthesis));
              setArticleReadable("les ");
            } else if (EstVoyelle(word.word[0])) {
              setArticle(addParenthesisWhenNeeded("l'", addParenthesis));
              setArticleReadable("l'");
            } else if (word.genre === "MASC") {
              setArticle(addParenthesisWhenNeeded("le", addParenthesis));
              setArticleReadable("le ");
            } else if (word.genre === "FEMI") {
              setArticle(addParenthesisWhenNeeded("la", addParenthesis));
              setArticleReadable("la ");
            } else {
              setArticle(undefined);
              setArticleReadable(undefined);
            }
          }
        } else {
          setArticle(undefined);
          setArticleReadable(undefined);
        }
      }
      if (word.images.length === 0) {
        console.log("Get images from Arasaac!");
        axios
          .get(ARASAAC_SEARCH_API + "/fr/bestsearch/" + word.word)
          .then((res) => {
            const pictos = res.data;
            axios
              .get(ARASAAC_SEARCH_API + "/" + pictos[0]["_id"], {
                params: {
                  plural: false,
                  color: true,
                  resolution: 500,
                  url: true,
                },
              })
              .then((picto) => {
                console.log("Set extra image to : " + picto.data.image);
                setExtraImage(picto.data.image);
              });
          })
          .catch((err) => console.log("No images found.."));
      }
    }
    return () => {
      setArticle(undefined);
      setNature(undefined);
      setExtraImage(null);
    };
  }, [word]);

  return (
    <>
      <Card sx={{ textAlign: "center" }}>
        {(word.images?.length > 0 || extraImage) && (
          <Box sx={{ mt: 2 }}>
            {word.images.map((currentImage: string, index: number) => (
              <img
                key={index}
                src={currentImage}
                width="164"
                height="164"
                alt={word.word}
              />
            ))}
            {extraImage && (
              <img src={extraImage} width="164" height="164" alt={word.word} />
            )}
          </Box>
        )}
        <CardContent>
          <Box sx={{ padding: 0, margin: 2 }}>
            <Typography variant="h5" fontStyle="italic" display="inline">
              {article}
            </Typography>
            <Typography variant="h4" display="inline">
              {" "}
            </Typography>
            <Typography variant="h4" display="inline">
              {word.word}
            </Typography>
            <TextToSpeech
              word={(articleReadable ? articleReadable : "") + word.word}
            />
            <Typography variant="body2" fontStyle="italic">
              {nature}
            </Typography>
          </Box>
          <Box my={2}>
            {word.synonyms.map((syn: string, i: number) => (
              <Typography
                component={Link}
                color="secondary"
                key={"synonym-" + i}
                to={"/?word=" + syn + "&back=" + word.word}
                variant="body1"
                fontStyle="italic"
                sx={{ cursor: "pointer", margin: "8px" }}
              >
                {syn}
              </Typography>
            ))}
          </Box>
          {word.definitions.map((def: string, i: number) => (
            <Box key={"def-" + i} display="flex">
              <Typography fontWeight="bold" sx={{ mr: 2 }}>
                {i + 1}.
              </Typography>
              <Typography component="div" variant="body1" align="left">
                {def}
              </Typography>
            </Box>
          ))}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          {isAuthenticated && (
            <Link to={"/admin/word/edit?word=" + word.word}>
              <IconButton aria-label="edit">
                <Edit color="secondary" />
              </IconButton>
            </Link>
          )}
          <IconButton aria-label="contact-support" onClick={openContactDialog}>
            <ContactSupport color="secondary" />
          </IconButton>
        </CardActions>
      </Card>
      <ContactDialog
        onClose={closeContactDialog}
        word={word}
        isOpen={isContactDialogOpen}
      />
    </>
  );
};

export default Word;
