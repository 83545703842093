import { Navigate, useRoutes } from "react-router-dom";
import Layout from "../components/Layout";
import HomePage from "../pages/HomePage";
import { AdminRoutes } from "./admin";

export const AppRoutes = () => {
  const routes = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <HomePage /> },
        { path: "admin/*", element: <AdminRoutes /> },
        { path: "*", element: <Navigate to="/" /> },
      ],
    },
  ];

  const element = useRoutes(routes);
  return <>{element}</>;
};
