import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../stores/store";
import { ThemeProvider, createTheme } from "@mui/material/styles";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  const theme = createTheme({
    typography: {
      fontFamily:
        '"Comic Sans MS", "Comic Sans", Arial, Tahoma, Verdana, sans-serif',
    },
    palette: {
      primary: {
        light: "#5e607e",
        main: "#333652",
        dark: "#0c1029",
      },
      secondary: {
        light: "#ffff64",
        main: "#fad02c",
        dark: "#c39f00",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
};
