import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../hooks";
import { selectVoice } from "../features/settings/settingsSlice";

const SettingsDialog = () => {
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const selectedVoice = useAppSelector((state) => state.settings.selectedVoice);
  const voices = useAppSelector((state) => state.settings.voices);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  const handleVoiceChange = (event: SelectChangeEvent<string>) => {
    dispatch(selectVoice(event.target.value));
  };

  return (
    <>
      <IconButton onClick={open}>
        <Settings fontSize="medium" color="secondary" />
      </IconButton>
      <Dialog fullWidth scroll="body" open={isOpen} onClose={close}>
        <DialogTitle fontWeight="bold" color="primary" textAlign="center">
          Paramètres
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" fontWeight="bold" color="primary" mb={1}>
            Voix
          </Typography>
          <Select
            value={selectedVoice}
            onChange={handleVoiceChange}
            fullWidth
            label="Voix"
          >
            {voices &&
              voices.map((v) => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button size="large" onClick={close}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettingsDialog;
