/********************************************************************************
 *  Copyright 2021, Pierre-Alain Etique                                         *
 *                                                                              *
 *  This file is part of Coloriƨation.                                          *
 *                                                                              *
 *  Coloriƨation is free software: you can redistribute it and/or modify        *
 *  it under the terms of the GNU General Public License as published by        *
 *  the Free Software Foundation, either version 3 of the License, or           *
 *  (at your option) any later version.                                         *
 *                                                                              *
 *  Coloriƨation is distributed in the hope that it will be useful,             *
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of              *
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the               *
 *  GNU General Public License for more details.                                *
 *                                                                              *
 *  You should have received a copy of the GNU General Public License           *
 *  along with Coloriƨation.  If not, see <https://www.gnu.org/licenses/>.      *
 *                                                                              *
 ********************************************************************************/

import AutomatFindPhons from "./AutomAutomat";
import PhonInW from "./PhonInW";
import TheWord from "./TheWord";

export default class PhonWord extends TheWord {
  private phons: PhonInW[];

  constructor(text: string, inFirst: number = 0, inLast: number = text.length) {
    super(text, inFirst, inLast);
    this.phons = new Array<PhonInW>();
  }

  /**
   * Ajoute le PhonInW à la liste des PhonInW pour le mot.
   * @param piw Le PhonInW à ajouter
   */
  public AddPhon(piw: PhonInW) {
    this.phons.push(piw);
  }

  /**
   * Retourne une représentation phonétique lexique.org du mot.
   * S'appelle "Phonetique()" dans la version VSTO
   */
  public ToPhonString(type: "simple" | "phonetic"): string {
    // reset phons array to avoid doubling phons with multiple calls
    this.phons = new Array<PhonInW>();
    AutomatFindPhons(this, type);
    let toReturn = "";
    for (let piw of this.phons) {
      toReturn = toReturn + piw.PhonToString(type);
    }
    return toReturn;
  }
}
