import { Box, Button, Grid, Typography } from "@mui/material";
import CardMenu, { CardMenuProps } from "../../components/CardMenu";
import { logout } from "../../features/auth/api/logout";
import { withAuth } from "../../features/auth/components/AuthAccess";

const adminMenuItems: CardMenuProps[] = [
  { title: "Voir commentaires", link: "/admin/comments", disabled: false },
  { title: "Ajouter un mot", link: "/admin/word/add", disabled: false },
  { title: "Changer le contenu", link: "/admin/content", disabled: true },
  { title: "Changer le mot de passe", link: "/admin/user", disabled: false },
];

const AdminPage = () => {
  return (
    <Box py={2}>
      <Grid container justifyContent="center">
        {adminMenuItems.map((menuItem, i) => (
          <Grid item key={i} m={1}>
            <CardMenu {...menuItem} />
          </Grid>
        ))}
      </Grid>
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography>Cliquer ici pour vous déconnecter</Typography>
        <Button
          variant="outlined"
          size="medium"
          sx={{ mt: 2 }}
          onClick={() => logout()}
        >
          Déconnexion
        </Button>
      </Box>
    </Box>
  );
};

export default withAuth(AdminPage);
