import { AppBar as MuiAppBar, Box, Link, Toolbar } from "@mui/material";

import AboutDialog from "./AboutDialog";
import logo from "../assets/logo-rect.png";
import SettingsDialog from "./SettingsDialog";

const AppBar = () => {
  return (
    <MuiAppBar elevation={3} position="static">
      <Toolbar>
        <Link href="/">
          <Box
            component="img"
            alt="Dico APIceras logo"
            src={logo}
            height={54}
          />
        </Link>
        <Box sx={{ flexGrow: 1 }}></Box>
        <AboutDialog />
        <SettingsDialog />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
