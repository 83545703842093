import { getDocs, limit, orderBy, query } from "firebase/firestore";
import { commentsCollection } from "../../../lib/firebase/firestore";
import { CommentType } from "../types";

export const getComments = async () => {
  const q = query(commentsCollection, orderBy("date", "asc"), limit(10));
  const comments: CommentType[] = [];
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    const docData = doc.data();
    comments.push({
      id: doc.id,
      word: docData.word,
      comment: docData.comment,
      date: docData.date.toDate(),
    });
  });
  return comments;
};
