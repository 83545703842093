import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CSVDownload } from "react-csv";
import { WordType } from "../../features/words/types";
import { exportWordsAsCsv } from "../../features/words/api";
import BackButton from "../../components/BackButton";
import { withAuth } from "../../features/auth/components/AuthAccess";

const EXPORTING: string = "exporting";
const EXPORTED: string = "exported";
const ERROR: string = "error";

const ExportPage = () => {
  const [exportingState, setExportingState] = useState<string>("");
  const [fullExport, setFullExport] = useState<boolean>(false);
  const [data, setData] = useState<WordType[]>([]);

  const exportAsCsv = () => {
    console.log("Exporting as csv...");
    setExportingState(EXPORTING);
    exportWordsAsCsv(fullExport)
      .then((words) => {
        setData(words);
        setExportingState(EXPORTED);
      })
      .catch((error) => {
        console.error("impossible to export: ", error);
        setExportingState(ERROR);
      });
  };

  return (
    <Box>
      <BackButton />
      <FormGroup sx={{ alignItems: "center" }}>
        <Typography variant="h5" color="primary">
          Créer un fichier csv avec les mots du dictionnaire
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={fullExport}
              onChange={() => setFullExport(!fullExport)}
            />
          }
          label="Exporter la totalité du dictionnaire ?"
        />
        <LoadingButton
          color={exportingState === EXPORTED ? "secondary" : "primary"}
          sx={{ margin: "8px" }}
          variant="contained"
          onClick={exportAsCsv}
          loading={exportingState === EXPORTING}
        >
          Exporter en CSV
        </LoadingButton>
        {exportingState === ERROR && (
          <Typography color="error">
            Problème lors de l'export contacter le support
          </Typography>
        )}
        {exportingState === EXPORTED && data != null ? (
          <CSVDownload data={data} filename={"fr.csv"} />
        ) : null}
      </FormGroup>
    </Box>
  );
};

export default withAuth(ExportPage);
