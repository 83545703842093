import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from "../../../lib/firebase";

export const getChangesRef = () => {
  const dbDoc = doc(collection(firestore, "info"), "database");
  return getDoc(dbDoc).then((doc) => {
    const data = doc.data();
    if (data) {
      return collection(dbDoc, data.version as string);
    }
  });
};
