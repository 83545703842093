// TODO this export part should be removed
import { getDocs, limit, orderBy, query } from "firebase/firestore";
import { wordsCollection } from "../../../lib/firebase/firestore";
import { mapFromDocumentData } from "../mapper";
import { WordType } from "../types";

// or be done by calling an endpoint
export const exportWordsAsCsv = async (fullExport: boolean = false) => {
  let q;
  if (fullExport) {
    q = query(wordsCollection, orderBy("index"));
  } else {
    q = query(wordsCollection, orderBy("index"), limit(20));
  }

  const querySnapshot = await getDocs(q);
  let words: WordType[] = [];
  querySnapshot.forEach((doc) => {
    if (doc.exists()) words.push(mapFromDocumentData(doc.data()));
  });
  return words;
};
