import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { deleteWord } from "../api";

interface DeleteDialogProps {
  isOpen: boolean;
  word: string;
  onClose: React.MouseEventHandler<HTMLElement>;
  onDelete: () => void;
}

const DeleteWordDialog = ({
  isOpen,
  word,
  onClose,
  onDelete,
}: DeleteDialogProps) => {
  const deleteWordFromDatabase = (e: React.MouseEvent<HTMLElement>) => {
    deleteWord(word).then((deleted) => {
      if (!deleted) {
        console.error("Word not deleted");
      } else {
        console.log("Word deleted");
        onClose(e);
        onDelete();
      }
    });
    onClose(e);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-confirmation-title"
    >
      <DialogTitle id="delete-confirmation-title">
        Êtes-vous sûrs de vouloir supprimer ce mot ?
      </DialogTitle>
      <DialogActions>
        <Button onClick={deleteWordFromDatabase} color="error">
          Supprimer
        </Button>
        <Button onClick={onClose} autoFocus>
          Annuler
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWordDialog;
