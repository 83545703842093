import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import { getComments } from "../../features/comments/api/get";
import { CommentType } from "../../features/comments/types";
import { createSearchParams, useNavigate } from "react-router-dom";
import { deleteComment } from "../../features/comments/api/delete";
import BackButton from "../../components/BackButton";
import { withAuth } from "../../features/auth/components/AuthAccess";

const CommentsPage = () => {
  const [comments, setComments] = useState<CommentType[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    // load comments
    getComments()
      .then((data) => {
        setComments(data);
      })
      .catch((error) => {
        console.error("Imposible to get comments", error);
      });
  }, []);

  const editWord = (comment: CommentType) => {
    deleteComment(comment.id!).then(() => {
      goToWord(comment);
    });
  };

  const goToWord = (comment: CommentType) => {
    navigate({
      pathname: "/",
      search: createSearchParams({
        word: comment.word,
        back: "admin",
      }).toString(),
    });
  };

  return (
    <Box>
      <BackButton />
      {comments.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Mot</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Commentaire</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Corriger</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Voir mot</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {comments.map((comment, i) => (
              <TableRow key={"comment-" + i}>
                <TableCell>{comment.word}</TableCell>
                <TableCell>{comment.comment}</TableCell>
                <TableCell>
                  {comment.date.toLocaleDateString("fr-ch") +
                    " " +
                    comment.date.toLocaleTimeString("fr-ch")}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => editWord(comment)}>
                    <Edit />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => goToWord(comment)}>
                    <Visibility />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default withAuth(CommentsPage);
