import { useEffect, useState } from "react";
import HomeContent from "../components/HomeContent";
import { useAppDispatch } from "../hooks";
import { initVoices, selectVoice } from "../features/settings/settingsSlice";

const HomePage = () => {
  const dispatch = useAppDispatch();
  const synth = window.speechSynthesis;
  const [frVoices, setFrVoices] = useState<SpeechSynthesisVoice[]>([]);

  useEffect(() => {
    const updateVoices = () => {
      setFrVoices(synth.getVoices().filter((v) => v.lang.includes("fr")));
    };
    if (synth && synth.addEventListener) {
      // Update voices when the 'voiceschanged' event is triggered
      synth.addEventListener("voiceschanged", updateVoices);

      // Initial update
      updateVoices();
    }

    return () => {
      if (synth) {
        if (synth.removeEventListener) {
          synth.removeEventListener("voiceschanged", updateVoices);
        }
        synth.cancel();
      }
    };
  }, [synth]);

  useEffect(() => {
    if (frVoices.length > 0) {
      dispatch(initVoices(frVoices.map((v) => v.name)));
      const defaultVoice = frVoices.filter((v) => v.default);
      if (defaultVoice.length > 0) {
        dispatch(selectVoice(defaultVoice[0].name));
      } else {
        dispatch(selectVoice(frVoices[0].name));
      }
    }
  }, [frVoices, dispatch]);

  return <HomeContent />;
};

export default HomePage;
