import { Dispatch, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Check, Close, Delete } from "@mui/icons-material";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import BackButton from "../../components/BackButton";
import { WordType } from "../../features/words/types";
import DeleteWordDialog from "../../features/words/components/DeleteWordDialog";
import WordForm from "../../features/words/components/WordForm";
import { getWord, renameWord, updateWord } from "../../features/words/api";
import { withAuth } from "../../features/auth/components/AuthAccess";

const EditWordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const wordToEdit = searchParams.get("word");

  const goBack = () => {
    navigate(-1);
  };

  if (!wordToEdit) {
    goBack();
  }

  const [word, setWord] = useState<WordType>();
  useEffect(() => {
    if (wordToEdit) {
      getWord(wordToEdit)
        .then((databaseWord) => {
          if (databaseWord) {
            setWord(databaseWord);
          } else {
            console.error("word not found");
            goBack();
          }
        })
        .catch((error) => {
          console.error(error);
          goBack();
        });
    }
    // eslint-disable-next-line
  }, [wordToEdit]);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

  const editWord = async () => {
    if (wordToEdit && word) {
      const newWord: WordType = {
        ...word,
        word: word.word.trim().toLowerCase(),
        definitions: word.definitions.filter((d) => d.trim().length > 0),
        synonyms: word.synonyms.filter((s) => s.trim().length > 0),
      };
      const nullifiedWord = JSON.parse(
        JSON.stringify(newWord),
        (key, value) => {
          return value === undefined ? null : value;
        }
      );

      let res;
      if (
        wordToEdit.trim().toLowerCase() !== newWord.word.trim().toLowerCase()
      ) {
        res = await renameWord(wordToEdit, nullifiedWord);
      } else {
        res = await updateWord(nullifiedWord);
      }

      if ("error" in res) {
        console.error(res.error);
      } else {
        console.log("Word updated");
        goBack();
      }
    } else {
      console.error("word or wordToEdit is undefined");
    }
  };

  return (
    <>
      {word && (
        <Box pb={2}>
          <BackButton />
          <Card sx={{ m: 2 }}>
            <CardContent>
              <WordForm
                word={word}
                setWord={setWord as Dispatch<React.SetStateAction<WordType>>}
              />
            </CardContent>
            <CardActions>
              <IconButton
                aria-label="remove-word"
                onClick={() => setDeleteDialogOpen(true)}
              >
                <Delete color="error" fontSize="large" />
              </IconButton>
              <Grid container justifyContent="flex-end">
                <IconButton onClick={goBack}>
                  <Close fontSize="large" />
                </IconButton>
                <IconButton onClick={editWord}>
                  <Check color="success" fontSize="large" />
                </IconButton>
              </Grid>
            </CardActions>
          </Card>
          <DeleteWordDialog
            word={word.word}
            isOpen={isDeleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            onDelete={goBack}
          />
        </Box>
      )}
    </>
  );
};

export default withAuth(EditWordPage);
