import { doc, setDoc } from "firebase/firestore";
import { wordsCollection } from "../../../lib/firebase/firestore";
import { WordType } from "../types";
import { addChange } from "../../changes/api/add";

export const addWord = async (word: WordType) => {
  return setDoc(doc(wordsCollection, word.word), word)
    .then(() => {
      addChange(word, "add");
      return word;
    })
    .catch((e) => {
      console.error("Erreur en ajoutant " + word.word);
      console.error(e);
      return {
        error: "Erreur en ajoutant " + word.word,
      };
    });
};
