import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { Image, Add, Edit, Cancel } from "@mui/icons-material";
import {
  IconButton,
  Typography,
  TextField,
  Select,
  Box,
  Stack,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material";
import { WordType } from "../types";
import ImageSelectionDialog from "../../images/components/ImageSelectionDialog";

interface WordFormProps {
  word: WordType;
  setWord: Dispatch<SetStateAction<WordType>>;
}

const possibleNatures = [
  "NOM",
  "VER",
  "ADJ",
  "ADV",
  "ART",
  "AUX",
  "CON",
  "ONO",
  "PRE",
  "PRO",
];

const WordForm = ({ word, setWord }: WordFormProps) => {
  const [isImageDialogOpen, setImageDialogOpen] = useState(false);

  const [editWordText, setEditWordText] = useState<boolean>(word.word === "");

  const handleImagesChange = (images: string[]) => {
    setWord({ ...word, images: images });
  };

  const handleNatureChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setWord({
      ...word,
      nature: typeof value === "string" ? value.split(",") : value,
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWord({ ...word, [event.target.name]: event.target.value });
  };

  const addDefinition = () => {
    setWord({ ...word, definitions: [...word.definitions, ""] });
  };

  const handleDefinitionChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let defs = [...word.definitions];
    defs[index] = event.target.value;
    setWord({ ...word, definitions: defs });
  };

  const addSynonym = () => {
    setWord({ ...word, synonyms: [...word.synonyms, ""] });
  };

  const handleSynonymChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let syns = [...word.synonyms];
    syns[index] = event.target.value;
    setWord({ ...word, synonyms: syns });
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6" color="error" sx={{ m: 2, mt: 4 }}>
        Modifier les images associées au mot
      </Typography>
      {word.images?.length > 0 && (
        <Box sx={{ mt: 2 }}>
          {word.images.map((currentImage, index) => (
            <img
              key={index}
              src={currentImage}
              width="164"
              height="164"
              alt={word.word}
            />
          ))}
        </Box>
      )}
      <IconButton
        sx={{ alignSelf: "center" }}
        aria-label="add-image"
        onClick={() => setImageDialogOpen(true)}
      >
        <Image fontSize="large" />
      </IconButton>
      <Typography variant="h6" color="error" sx={{ m: 2, mt: 4 }}>
        Modifier le mot
      </Typography>
      {editWordText ? (
        <Box display="flex" flexDirection="row">
          <TextField value={word.word} name="word" onChange={handleChange} />
          <IconButton onClick={() => setEditWordText(false)}>
            <Cancel />
          </IconButton>
        </Box>
      ) : (
        <Typography variant="h4" component="span" sx={{ margin: "8px" }}>
          {word.word}
          <IconButton onClick={() => setEditWordText(true)}>
            <Edit />
          </IconButton>
        </Typography>
      )}
      <Typography variant="h6" color="error" sx={{ m: 2, mt: 4 }}>
        Modifier la nature et le genre du mot
      </Typography>
      <Stack direction="row" mx={6} spacing={4}>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="nature-label">Nature</InputLabel>
          <Select
            labelId="nature-label"
            name="nature"
            id="nature"
            multiple
            value={word.nature ?? []}
            onChange={handleNatureChange}
            input={<OutlinedInput label="Nature" />}
          >
            {possibleNatures.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          sx={{ width: 200 }}
          select
          name="genre"
          id="genre"
          label="Genre"
          value={word.genre}
          SelectProps={{
            native: true,
          }}
          onChange={handleChange}
        >
          <option>MASC</option>
          <option>FEMI</option>
          <option></option>
        </TextField>
        <TextField
          select
          sx={{ width: 200 }}
          label="Article"
          name="article"
          id="article"
          value={word.article}
          SelectProps={{
            native: true,
          }}
          onChange={handleChange}
        >
          <option>l'</option>
          <option>les</option>
          <option>le</option>
          <option>la</option>
          <option></option>
        </TextField>
        <TextField
          select
          sx={{ width: 200 }}
          label="Nombre"
          name={"nombre"}
          id={"nombre"}
          value={word.nombre}
          SelectProps={{
            native: true,
          }}
          onChange={handleChange}
        >
          <option>SING</option>
          <option>PLUR</option>
          <option></option>
        </TextField>
      </Stack>
      <Typography variant="h6" color="error" sx={{ m: 2, mt: 4 }}>
        Modifier les définitions
      </Typography>
      {word.definitions.map((definition, i) => (
        <Box key={"definition-" + i} display="flex" sx={{ m: 2, width: "90%" }}>
          <Typography fontWeight="bold" sx={{ mr: 2 }}>
            {i + 1}.
          </Typography>
          <TextField
            autoFocus
            multiline
            fullWidth
            value={definition}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleDefinitionChange(e, i)
            }
          />
        </Box>
      ))}
      {word.definitions.length < 5 && (
        <IconButton sx={{ m: 0 }} onClick={addDefinition}>
          <Add />
        </IconButton>
      )}
      <Typography variant="h6" color="error" sx={{ m: 2, mt: 4 }}>
        Modifier les synonymes
      </Typography>
      {word.synonyms.map((synonym, i) => (
        <Box key={"synonym-" + i} display="flex" sx={{ m: 2, width: "90%" }}>
          <Typography fontWeight="bold" sx={{ mr: 2 }}>
            {i + 1}.
          </Typography>
          <TextField
            autoFocus
            multiline
            fullWidth
            value={synonym}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleSynonymChange(e, i)
            }
          />
        </Box>
      ))}
      {word.synonyms.length < 3 && (
        <IconButton sx={{ m: 0 }} onClick={addSynonym}>
          <Add />
        </IconButton>
      )}
      <ImageSelectionDialog
        isOpen={isImageDialogOpen}
        onClose={() => setImageDialogOpen(false)}
        word={word.word}
        save={handleImagesChange}
        images={word.images}
      />
    </Box>
  );
};

export default WordForm;
