import { WordType } from "../types";
import { wordsCollection } from "../../../lib/firebase/firestore";
import { mapFromDocumentData } from "../mapper";
import { getDocs, query, where, limit } from "firebase/firestore";
import { getNextString } from "../utils";
import { SettingsState } from "../../settings/settingsSlice";
import { getWord } from "./get";
import { logResult, logSearch } from "../../analytics";
import PhonWord from "../../../ColorizationPart/Core/PhonWord";

export const searchWords = async (
  word: PhonWord,
  settings: SettingsState
): Promise<WordType[]> => {
  let startsWith = word.GetWord();
  let exactWord: WordType | null = null;
  let words: WordType[] = [];
  const searchType =
    settings.helpLevel === 0
      ? "word"
      : settings.helpLevel === 1
      ? "phonetic"
      : "simple";

  if (searchType !== "word") {
    // first get exact word if it exists
    exactWord = await getWord(startsWith);

    // then get phonetic with/without ending 'e' (ç sound)
    startsWith = word.ToPhonString(searchType);
    let qE;
    if (startsWith.endsWith("ç")) {
      qE = query(
        wordsCollection,
        where(searchType, "==", startsWith.substring(0, startsWith.length - 1)),
        limit(settings.maxResults)
      );
    } else {
      qE = query(
        wordsCollection,
        where(searchType, "==", startsWith + "ç"),
        limit(settings.maxResults)
      );
    }
    const querySnapshotE = await getDocs(qE);
    querySnapshotE.forEach((doc) =>
      words.push(mapFromDocumentData(doc.data()))
    );
  }

  console.log("Search with type: " + searchType);
  console.log(
    "Search words for word = " +
      word.GetWord() +
      " and phonetic = " +
      startsWith
  );

  logSearch(word.GetWord());

  const endSearch = getNextString(startsWith);
  const q = query(
    wordsCollection,
    where(searchType, ">=", startsWith),
    where(searchType, "<", endSearch),
    limit(Math.max(settings.maxResults - words.length, 1))
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.exists()) {
      words.push(mapFromDocumentData(doc.data()));
    }
  });

  words.sort((w1, w2) =>
    w1.frequency > w2.frequency ? -1 : w1.frequency < w2.frequency ? 1 : 0
  );
  if (exactWord !== null) {
    words = [exactWord, ...words];
  }
  words = words.filter(
    (v, i, a) => a.findIndex((v2) => v2.word === v.word) === i
  );

  // add statistics of returned words
  words.forEach((w) => {
    logResult(w.word);
  });
  return words;
};
