import { getAnalytics } from "firebase/analytics";
import { FirebaseOptions, initializeApp } from "firebase/app";
import { ReCaptchaV3Provider, initializeAppCheck } from "firebase/app-check";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
  appId: process.env.REACT_APP_APPID,
};

export const firebase = initializeApp(firebaseConfig);

/* auth component */
export const auth = getAuth(firebase);

/* anaylytics component */
export const analytics = getAnalytics(firebase);

/* appcheck */
export const appcheck = initializeAppCheck(firebase, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_CAPTCHA_KEY!),
  isTokenAutoRefreshEnabled: true,
});

/* firestore component */
export const firestore = getFirestore(firebase);
