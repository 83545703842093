import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "../pages/admin/AdminPage";
import UpdatePasswordPage from "../pages/admin/UpdatePasswordPage";
import ExportPage from "../pages/admin/ExportPage";
import CommentsPage from "../pages/admin/CommentsPage";
import AddWordPage from "../pages/admin/AddWordPage";
import EditWordPage from "../pages/admin/EditWordPage";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route index={true} element={<AdminPage />} />
      <Route path="user" element={<UpdatePasswordPage />} />
      <Route path="export" element={<ExportPage />} />
      <Route path="comments" element={<CommentsPage />} />
      <Route path="word/add" element={<AddWordPage />} />
      <Route path="word/edit" element={<EditWordPage />} />
      <Route path="*" element={<Navigate to="/admin" />} />
    </Routes>
  );
};
