import { collection, doc, getDoc } from "firebase/firestore";
import { firestore } from ".";

/* main collections paths. fr only for now */
const frDoc = doc(collection(firestore, "dictionaries"), "fr");

/* words */
export const wordsCollection = collection(frDoc, "words");

/* images-index */
export const imagesIndexCollection = collection(firestore, "images-index");

/* comments */
export const commentsCollection = collection(firestore, "comments");

/* get database version */
export const dbVersionCollection = async () => {
  const dbDoc = doc(collection(firestore, "info"), "database");
  return await getDoc(dbDoc).then((doc) => {
    const data = doc.data();
    if (data) {
      return collection(dbDoc, data.version as string);
    }
    return null;
  });
};
