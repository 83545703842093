import { Button, TextField, Typography } from "@mui/material";
import { ChangeEvent, FormEvent, useState } from "react";
import { LoginCredentials, login } from "../api/login";

export const LoginForm = () => {
  const [loginData, setLoginData] = useState<LoginCredentials>({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string>();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setLoginData({
      ...loginData,
      [event.currentTarget.id]: event.currentTarget.value,
    });
  };

  const handleLogin = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    login(loginData)
      .then((data) => {
        if (!data.user) {
          setError("Connexion impossible...");
        }
      })
      .catch((e) => {
        setError("Connexion impossible...");
      });
  };

  return (
    <>
      <form
        onSubmit={handleLogin}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography m={1} variant="h4" component="h1" color="primary">
          Connectez-vous
        </Typography>
        <TextField
          sx={{ m: 1 }}
          id="email"
          type="email"
          label="Email"
          onChange={handleChange}
        />
        <TextField
          id="password"
          type="password"
          label="Mot de passe"
          onChange={handleChange}
        />
        <Button
          disabled={error ? true : false}
          sx={{ m: 2 }}
          type="submit"
          variant="contained"
          color="primary"
        >
          Se connecter
        </Button>
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </form>
    </>
  );
};
