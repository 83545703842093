import { Box } from "@mui/material";
import { Masonry } from "@mui/lab";
import Word from "./Word";
import { WordType } from "../types";

interface WordsListProps {
  words: WordType[];
}

const WordsList = ({ words }: WordsListProps) => {
  return (
    <Box sx={{ py: words.length > 0 ? 2 : 0, px: 1 }}>
      <Masonry sx={{ m: 0 }} spacing={2} columns={{ xs: 1, md: 2, xxl: 3 }}>
        {words.map((word, index) => (
          <Box key={index}>
            <Word word={word} />
          </Box>
        ))}
      </Masonry>
    </Box>
  );
};

export default WordsList;
