import { useEffect, useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ImageList,
  InputBase,
  Paper,
  Divider,
  ImageListItem,
  Box,
  ImageListItemBar,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { ImageLinkType } from "../types";
import { getImages } from "../api/get";

interface ImageSelectionDialogProps {
  images: string[];
  word: string;
  isOpen: boolean;
  save: (images: string[]) => void;
  onClose: () => void;
}

const ImageSelectionDialog = ({
  images,
  word,
  isOpen,
  save,
  onClose,
}: ImageSelectionDialogProps) => {
  const [searchedImages, setSearchedImages] = useState<ImageLinkType[]>([]);
  const [selectedImages, setSelectedImages] = useState<string[]>(images);
  const [searchWord, setSearchWord] = useState<string>(word);

  const filterAndSetSearchImages = (images: ImageLinkType[]) => {
    setSearchedImages(
      images.filter((image) => !selectedImages.includes(image.url))
    );
  };

  const imageSearchSubmit = async (
    searchWord: string,
    setSearchedImages: Function
  ) => {
    setSearchedImages(await getImages(searchWord));
  };

  useEffect(() => {
    if (isOpen) {
      imageSearchSubmit(word, filterAndSetSearchImages);
    }
    return () => filterAndSetSearchImages([]);
    // eslint-disable-next-line
  }, [isOpen, word]);

  const handleSearchWordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchWord(event.target.value);
  };

  const handleImageSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    imageSearchSubmit(searchWord, filterAndSetSearchImages);
  };

  const selectImage = (imageLink: ImageLinkType) => {
    setSelectedImages([...selectedImages, imageLink.url]);
    setSearchedImages(
      searchedImages.filter((image) => image.url !== imageLink.url)
    );
  };

  const unselectImage = (imageLink: ImageLinkType) => {
    setSearchedImages([...searchedImages, imageLink]);
    setSelectedImages(
      selectedImages.filter((image) => image !== imageLink.url)
    );
  };

  const saveImages = () => {
    console.log("Assigning images to word");
    save(selectedImages);
    onClose();
  };

  const displayImageWithUrl = (url: string, index: number) => {
    const name = url.split("/").slice(-1)[0].split(".")[0].split("_")[0];
    return (
      <ImageListItem
        key={index}
        onClick={() => unselectImage({ name: name, url: url })}
        sx={{ maxWidth: 100 }}
      >
        <img
          src={url}
          srcSet={url}
          loading="lazy"
          alt={`${name}-${index}`}
          title={name}
        />
      </ImageListItem>
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        Image pour <strong>{word}</strong>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Box id="scroll-dialog-description" tabIndex={-1}>
          {selectedImages && (
            <ImageList
              sx={{
                display: "flex",
                flexWrap: "nowrap",
                border: selectedImages.length > 0 ? 2 : 0,
                borderColor: "primary.main",
              }}
            >
              {selectedImages.map((url, index) =>
                displayImageWithUrl(url, index)
              )}
            </ImageList>
          )}
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              mb: "24px",
              mx: "auto",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1 }}
              type="text"
              placeholder="Search"
              value={searchWord}
              onChange={handleSearchWordChange}
            />
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              type="submit"
              onClick={handleImageSearchSubmit}
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <ImageList cols={3}>
            {searchedImages.map((imageLink, index) => (
              <Box key={index}>
                <ImageListItem onClick={() => selectImage(imageLink)}>
                  <img
                    src={imageLink.url}
                    srcSet={imageLink.url}
                    loading="lazy"
                    alt={`${imageLink.name}-${index}`}
                    title={imageLink.name}
                  />
                  <ImageListItemBar title={imageLink.name} />
                </ImageListItem>
              </Box>
            ))}
          </ImageList>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveImages}>Save</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageSelectionDialog;
