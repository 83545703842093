import { Box } from "@mui/material";
import { UpdatePasswordForm } from "../../features/auth/components/UpdatePasswordForm";
import BackButton from "../../components/BackButton";
import { withAuth } from "../../features/auth/components/AuthAccess";

const UpdatePasswordPage = () => {
  return (
    <Box>
      <BackButton />
      <UpdatePasswordForm />
    </Box>
  );
};

export default withAuth(UpdatePasswordPage);
