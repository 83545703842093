import { logEvent } from "firebase/analytics";
import { analytics } from "../../lib/firebase";

export const logSearch = (search: string) => {
  logEvent(analytics, "search", {
    search_term: search,
  });
};

export const logResult = (word: string) => {
  logEvent(analytics, "result", {
    result: word,
  });
};
