import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AboutInfo from "./AboutInfo";
import { Info } from "@mui/icons-material";

const AboutDialog = () => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const open = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={open}>
        <Info fontSize="medium" color="secondary" />
      </IconButton>
      <Dialog fullWidth scroll="body" open={isOpen} onClose={close}>
        <DialogTitle fontWeight="bold" color="primary" textAlign="center">
          À propos
        </DialogTitle>
        <DialogContent>
          <AboutInfo />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button size="large" onClick={close}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AboutDialog;
