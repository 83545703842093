import { Box, Button, TextField, Typography } from "@mui/material";
import React, { FormEvent, useState } from "react";
import { resetPassword } from "../api/resetPassword";

export const UpdatePasswordForm = () => {
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [error, setError] = useState<{ old?: string; new?: string }>();
  const [success, setSuccess] = useState<string>();
  const isError = !error || Boolean(error.new) || Boolean(error.old);

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.currentTarget.value);
    checkFields();
  };

  const handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOldPassword(event.currentTarget.value);
    checkFields();
  };

  const checkFields = () => {
    if (!newPassword) {
      setError({ ...error, new: "Votre nouveau mot de passe ?" });
      return;
    }
    if (newPassword.length <= 6) {
      setError({ ...error, new: "Mot de passe trop court." });
      return;
    }
    if (!oldPassword) {
      setError({ ...error, old: "Mot de passe incorrect" });
      return;
    }
    setError({});
  };

  const updatePassword = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    checkFields();
    if (!isError) {
      resetPassword(oldPassword, newPassword)
        .then(() => {
          console.log("Mot de passe mis à jour.");
          setOldPassword("");
          setNewPassword("");
          setError(undefined);
          setSuccess("Mot de passe mis à jour.");
        })
        .catch((e) => {
          console.error("Password update failed!", e);
          if (e.code === "auth/weak-password") {
            setError({ ...error, new: "Mot de passe trop faible." });
          } else if (e.code === "auth/wrong-password") {
            setError({ ...error, old: "Mot de passe incorrect." });
          } else {
            setError({
              ...error,
              new: "Impossible de modifier le mot de passe.",
            });
          }
        });
    }
  };
  return (
    <form onSubmit={updatePassword}>
      <Box display="flex" flexDirection="column" alignItems="center" py={2}>
        <Typography variant="h5" color="primary">
          Changez votre mot de passe
        </Typography>
        <TextField
          id="oldPassword"
          type="password"
          label="Ancien mot de passe"
          onChange={handleOldPasswordChange}
          value={oldPassword}
          error={Boolean(error?.old)}
          helperText={error?.old}
          sx={{ mt: 2 }}
        />
        <TextField
          id="newPassword"
          type="password"
          label="Nouveau mot de passe"
          onChange={handleNewPasswordChange}
          value={newPassword}
          error={Boolean(error?.new)}
          helperText={error?.new}
          sx={{ my: 1 }}
        />
        <Button
          disabled={isError}
          type="submit"
          variant="contained"
          sx={{ mb: 2 }}
        >
          Changer le mot de passe
        </Button>
        {success && <Typography color="success">{success}</Typography>}
      </Box>
    </form>
  );
};
