import { Container, Paper } from "@mui/material";
import { Outlet } from "react-router-dom";

import AppBar from "./AppBar";
import Footer from "./Footer";

const Layout = () => {
  return (
    <Container maxWidth="lg" disableGutters>
      <AppBar />
      <Paper elevation={3} sx={{ borderRadius: 0 }}>
        <Outlet />
      </Paper>
      <Footer />
    </Container>
  );
};

export default Layout;
