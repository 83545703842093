import { doc, setDoc } from "firebase/firestore";
import { WordType } from "../../words/types";
import { getChangesRef } from "./getRef";

export const addChange = async (word: Partial<WordType>, action: String) => {
  const collection = await getChangesRef();
  if (collection) {
    setDoc(doc(collection, word.word), {
      ...word,
      action: action,
    }).then(() => {
      console.log(`'${word.word}' ajouté au versioning pour '${action}'`);
    });
  }
};
