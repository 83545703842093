import { VolumeUp } from "@mui/icons-material";
import React from "react";
import { Button } from "@mui/material";
import { useAppSelector } from "../hooks";

type Props = {
  word: string;
  inSearchBar?: boolean;
};

const TextToSpeech: React.FC<Props> = ({ word, inSearchBar = false }) => {
  const [utterance, setUtterance] = React.useState<SpeechSynthesisUtterance>();
  const selectedVoice = useAppSelector((state) => state.settings.selectedVoice);

  React.useEffect(() => {
    const u = new SpeechSynthesisUtterance(word);
    u.pitch = 1;
    u.rate = 1;
    u.volume = 1;

    const synth = window.speechSynthesis;
    const frVoices = synth.getVoices().filter((v) => v.lang.includes("fr"));
    if (selectedVoice && selectedVoice !== "") {
      u.voice = frVoices.find((v) => v.name === selectedVoice)!;
    }
    setUtterance(u);
  }, [word, selectedVoice]);

  const handleButtonClick = () => {
    if (utterance) {
      const synth = window.speechSynthesis;
      synth.speak(utterance);
    }
  };

  return (
    <Button
      disabled={word && selectedVoice ? false : true}
      sx={{ display: "inline", pb: inSearchBar ? 0 : "auto" }}
      onClick={handleButtonClick}
    >
      <VolumeUp />
    </Button>
  );
};

export default TextToSpeech;
