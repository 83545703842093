import {
  EmailAuthProvider,
  User,
  UserCredential,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { auth } from "../../../lib/firebase";

export const reauthenticate = (
  user: User,
  password: string
): Promise<UserCredential> => {
  return reauthenticateWithCredential(
    user,
    EmailAuthProvider.credential(user.email!, password)
  );
};

export const resetPassword = async (
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  const user = auth.currentUser!;
  await reauthenticate(user, oldPassword);
  return updatePassword(user, newPassword);
};
